.inputLabels{
    font-size: 12px;
    font-weight: bold;
}
/* .title{
    margin: 5px 0px 5px 60px;
    font-size: 20px;
} */
.staffFormTable{
    margin: auto;
}

.staffEntryBtn{
    margin: 33px 0px 0px 0px;
    width: 200px;
}

.wrapperHeightStaff{
    max-height: 530px;
}

.excelUpload{
    display: flex;
    flex-direction: row;
    text-align: center;
}

.staffExcelEntryBtn{
    margin: 1px 0px 0px 0px;
    width: 200px;
}
