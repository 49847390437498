.ck.ck-editor {
  max-width: 70vw;
  min-width: 500px;
  overflow: auto;
}

.ck-toolbar:hover {
  background-color: gainsboro !important;
}

.contact {
  margin: auto;
}

.contactForm {
  margin: auto;
}

.wrapperHeightContact {
  max-height: 800px;
}

.contactEditBtn {
  text-align: center;
  margin: 5px 0px 0px 0px;
  padding: 10px 80px 10px 80px;
}

.campusIntroduction {
  text-align: left;
  width: 127vh;
  /* max-height: 167px; */
}

.campusIntroduction textarea {
  /* width: -webkit-fill-available; */
  width: 93%;
  /* max-width: 90%; */
  height: 50px;
}

.ckEdit{
  text-align: left !important;
}
/* 
.dataTable td, .dataTable th {
  text-align: left !important;
} */