.noticeFormTable{
  /* margin: 0px 0px 0px 125px; */
  margin: auto;
}

.colorTable {
  background-color: aqua !important;
}
.noticeEntryBtn{
  margin: 33px 0px 0px 0px !important;
  width: 200px !important;
}

.radioBtn{
  margin: 0px 0px 0px 60px;
}
  
.radioBtn label, .radioBtn input{
  font-size: 17px;
  padding: 0px 0px 0px 17px;
  
}

.wrapperHeightNotice{
  max-height: 630px;
}

