.galleryCards {
  display: flex;
  overflow: auto;
  max-height: inherit !important;
  justify-content: space-around;
  flex-wrap: wrap;
}
.cards-list {
  z-index: 0;
  width: 100%;
}

#addItem {
  position: fixed;
  top: 0.5em;
  left: 50%;
  z-index: 5;
  transform: translate(-50%, +45%);
}

.card {
  /* border: 1px solid green; */
  margin: 45px auto;
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 40px;
  box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25),
    -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
  /* cursor: pointer; */
  transition: 0.4s;
}

.card .card_image {
  width: 100%;
  height: inherit;
  /* border-radius: 40px; */
}

.card .card_image img {
  width: inherit;
  position: absolute;
  top: 0;
  height: inherit;
  line-height: 20;
  border-radius: 40px;
  object-fit: cover;
  -webkit-transition: opacity 3s ease-in-out;
  -moz-transition: opacity 3s ease-in-out;
  -o-transition: opacity 3s ease-in-out;
  transition: opacity 3s ease-in-out;
}

.del {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  top: 10%;
  opacity: 50%;
  right: 10%;
  color: red;
  font-style: "Material Icons";
  font-size: 2rem;
}

.del:hover {
  opacity: 100%;
}

.imageDisplay {
  transition: all 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card .card_title {
  /* text-align: center; */
  position: absolute;

  font-family: sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  bottom: 8%;
  left: 8%;
}
.card_title {
  display: flex;
  justify-content:flex-start;
  flex-direction: column;
  overflow: hidden;
  /* background-color: rebeccapurple; */
}

.card_title span:nth-child(1) {
  /* background: #ece9e9; */
  font-size: .8rem;
  text-shadow: 3px 5px 2px #474747;
  
}

.card_title span:nth-child(2){
  font-size: .6rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 170px;
}

.card_title span:nth-child(3){
  font-size: x-small;
}

.card:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25),
    -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
}

.title-white {
  color: white;
}

.title-black {
  color: black;
}

@media all and (max-width: 500px) {
  .card-list {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
  }
}
