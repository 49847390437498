.callSpinner{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(147, 147, 147, 0.386);
    position: fixed;
    top:0px;
    min-width: 100vw;
    min-height: 100vh;
}

.modelSpinner{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(147, 147, 147, 0.386);
    position: absolute;
    top:0px;
    min-width: 77vw;
    min-height: 100vh;
}