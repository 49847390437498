/* .App {
 box-sizing: border-box;
 margin: 0px;
 padding: 0px;
 overflow: hidden;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #2b6eb5;
}
/* .login{
  margin: 145px 0px 0px 500px;
} */

.register {
  margin: 125px 0px 0px 465px;
}

.appButton {
  margin: 5px 23px 0px 170px;
  width: 150px;
  padding: 10px;
  background-color: skyblue;
  border: solid 1px;
  cursor: pointer;
}

.footer {
  background-color: black;
  color: aqua;
  text-align: center;
  font-size: 12px;
  min-height: 3vh; /* need this to prevent unnecessary scroll*/
}

.headder {
  background-color: black;
  color: aqua;
  text-align: right;
  font-size: 15px;
  margin-right: 0px;
  font-weight: bold;
  width: 100%;
  min-height: 3vh; /* need this to prevent unnecessary scroll*/
}

.tbl-btn {
  margin: 5px 10px 0px 0px;
}

/* common button properties */
.btn {
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  padding: 8px 25px;
}

.btn-gbl-blue {
  margin: 5px 0;
  background: blue;
}

.btn-gbl-green {
  background: green;
}

.btn-gbl-red {
  background: red;
}

.btnFlex {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center; /* added to fix btn sizing issues, vertically aligns flex item  to center.*/
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
