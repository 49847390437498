* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.container {
  height: calc(100vh - 6vh);
  display: flex;
  align-self: flex-start;
}
.container .section-content {
  width: 100%;
  overflow-y: auto; /* fix gallery overflow*/
}
.sidebar {
  background: #000;
  color: #fff;
  transition: all 0.5s;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 10px;
}
.logo {
  font-size: 20px;
}
.bars {
  display: flex;
  font-size: 25px;
  /* margin-left: 50px; */
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}
.link:hover {
  background: lightskyblue;
  color: #000;
  transition: all 0.5s;
}
.active {
  background: lightskyblue;
  color: #000;
}
.icon,
.link_text {
  font-size: 15px;
}
