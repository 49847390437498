.pubFormTable{
    /* margin: 0px 0px 0px 60px; */
    margin: auto;
}

.pubEntryBtn{
    margin: 0px 0px 0px 0px;
    width: 200px;
}

.wrapperHeightPublication{
    max-height: 630px;
}