hr{
  margin: 2px 0px 0px 0px;
  border: 1px solid black;
}

.text{
  display: flex;
  min-width: 200px;
  /* max-width: auto; */
  padding: 5px 5px 5px 10px;
  /* margin: 5px 15px 0px 0px;
  display: flex;
  padding: 5px 45px 5px 10px; */
}

.textLong {
  width: 600px ;
}

.title{
  /* margin: 5px 0px 5px 60px; */
  margin: auto;
  font-size: 20px;
}

.dateInput{
  width: 169px;
}

.from_dropdown{
  padding: 5px 35px 5px 0px;
  width: 227px;
}
.searchBox{
  padding: 5px 0px 5px 0px;
  border: 2px solid sandybrown;
  text-align: center;
  border-radius: 10px;
}

.tblWrapper{
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
}

.dataTable{
  font-size: 12px;
  border-collapse: collapse;
  width: 100%;
}
 
.dataTable td, .dataTable th{
  padding: 12px 12px;
  border: 1px solid #ddd;
  text-align: center;
  width: auto;
}

.dataTable th{
  background-color: darkblue;
  color: #ffffff;
  position: sticky;
  top: -2px;
}

.dataTable tbody tr:nth-child(even){
  background-color: #f5f5f5;
}

.passChkbox{
  display: flex;
  flex-direction: row;
}

.passChkbox small{
  margin: 4px 0px 0px -10px;
  font-size: 10px;
}

.search{
  text-align: end;
  margin: 0px 10px 4px 0px;
}

.searchBar{
  padding: 2px 0px 4px 7px;
  border: 2px solid black;
  border-radius: 10px;
}

.tableImage{
  max-width: 80px;
  max-height: 80px;
}

