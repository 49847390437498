.ckEdit {
  text-align: left !important;
}

.qaa-btn {
  color: white;
  background-color: rgba(255, 69, 0, 0.8);
  border: none;
  padding: 6px 16px;
  border-radius: 5px;
  transition: all 0.3s linear;
}

.qaa-btn:hover {
  cursor: pointer;
  background-color: rgba(255, 69, 0, 1);
  font-style: italic;
  /* opacity: 0.7; */
}
.qaa-btn:active {
  background-color: rgba(255, 69, 0, 0.5);
  color: #333;
}

.qaa-input-field {
  /* border: 2px solid blue; */
  padding: 6px 8px;
  border: none;
  border: 2px solid rgba(0, 0, 0, 0.4);
  /* opacity: .8; */
  border-radius: 5px;
  min-width: 300px;
  max-width: 400px;
  transition: all 0.6s ease-in-out;
}
.qaa-input-field:focus {
  background-color: lightgray;
}

.set-btn {
  background-color: rgb(190, 251, 0.8);
  color: black;
  min-width: 50px;
  max-width: 100px;
  width: 80px;
}
.set-btn:hover {
  background-color: rgb(190, 251, 1);
}

.set-btn:active {
  background-color: rgb(240, 251, 0.5);
  color: black;
}

.update-btn-color {
  background-color: rgb(8, 8, 164, 0.8);
  color: white;
}

.update-hover-color:hover {
  background-color: rgb(8, 8, 164, 1);
  color: white;
}

.update-active-color:active {
  background-color: rgb(8, 8, 164, .5);
  color: white;
}
