.modal {
  top: 0;
  z-index: 999;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgb(0 0 0 / 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .modal__container {
    width: 100%;
    margin: 12px;
    display: block;
    .modal__box {
      // max-width: 650px;
      border-radius: 6px;
      margin: 0 auto;
      padding: 2rem;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .modal__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          line-height: 1;
        }
        button {
          background: red;
          border: 0.5px solid black;
          border-radius: 10%;
          padding: 2.5px;
          svg {
            font-size: 1rem;
            color: #fff;
          }
        }
      }
      .modal__content {
        max-height: 70vh;
        overflow: auto;
        margin-bottom: 1rem;
        table {
          border: 0.5px solid rgb(0 0 100 / 0.12);
          border-collapse: collapse;
          position: relative;
          th,
          td {
            border: 0.5px solid rgb(0 0 100 / 0.12);
            padding: 6px 12px;
          }
          td {
            font-size: 13px;
          }
        }
        // form
        form {
          display: flex;
          flex-direction: column;
          gap: 12px;
          button {
            border: 1px solid var(--primary) !important;
            color: var(--primary) !important;
            &:hover {
              background-color: var(--primary) !important;
              color: white !important;
            }
          }
        }
        .register__modal {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
          gap: 12px;
        }
      }
      .modal__actions {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
      }
    }
  }
}
